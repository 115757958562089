$primary: #24515f !default;
$secondary: #8ebb38 !default;
$tertiary: #4ba5ff;
$quaternary: #f7ad5a;
$primary-light: #b6c5ca;
$secondary-light: #ddeac3;
$tertiary-light: #c9e4f5;
$quaternary-light: #f7e4ca;
$theme-contrast-color: #fff !default;
$theme_1: #aa73ea;
$theme_1-light: #d6c6ee;
$theme_2: #f8de45;
$theme_2-light: #f5eac8;
$theme_3: #2f60ff;
$theme_3-light: #a2c3ff;

@import '@/src/scss/preset/meierijstadbeweegt.scss';
@import "~daterangepicker";

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

main {
  margin-bottom: 100px;  //footer flavor image spacing
}

.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between;
}

.m-text-height {
  min-height: 73px;
}

.min-h-100 {
  min-height: 100% !important;
}

// Filter svg objects to other colors (we first transform to pure black and then calculate back to colors
.svg-primary {
  filter: brightness(0) saturate(100%) invert(25%) sepia(33%) saturate(867%) hue-rotate(148deg) brightness(89%) contrast(83%);
}

.svg-secondary {
  filter: brightness(0) saturate(100%) invert(65%) sepia(73%) saturate(419%) hue-rotate(38deg) brightness(93%) contrast(81%);
}

.svg-tertiary {
  filter: brightness(0) saturate(100%) invert(85%) sepia(26%) saturate(303%) hue-rotate(176deg) brightness(102%) contrast(92%);
}

.svg-quaternary {
  filter: brightness(0) saturate(100%) invert(91%) sepia(15%) saturate(3891%) hue-rotate(317deg) brightness(102%) contrast(94%);
}

.svg-primary-light {
  filter: brightness(0) saturate(100%) invert(89%) sepia(15%) saturate(176%) hue-rotate(149deg) brightness(82%) contrast(88%);
}

.svg-secondary-light {
  filter: brightness(0) saturate(100%) invert(87%) sepia(25%) saturate(216%) hue-rotate(38deg) brightness(103%) contrast(89%);
}

.svg-tertiary-light {
  filter: brightness(0) saturate(100%) invert(85%) sepia(26%) saturate(303%) hue-rotate(176deg) brightness(102%) contrast(92%);
}

.svg-quaternary-light {
  filter: brightness(0) saturate(100%) invert(94%) sepia(96%) saturate(291%) hue-rotate(308deg) brightness(99%) contrast(95%);
}

.svg-theme_1-light {
  filter: brightness(0) saturate(100%) invert(85%) sepia(7%) saturate(2461%) hue-rotate(204deg) brightness(99%) contrast(87%);
}

.svg-theme_2-light {
  filter: brightness(0) saturate(100%) invert(93%) sepia(21%) saturate(353%) hue-rotate(341deg) brightness(101%) contrast(92%);
}

.svg-theme_3-light {
  filter: brightness(0) saturate(111%) invert(72%) sepia(26%) saturate(1298%) hue-rotate(187deg) brightness(101%) contrast(103%)
}

.svg-title {
  font-size: 1.2rem;
}

.border-lg {
  border-width: 6px !important;
}

//.wrapper {
//  margin-bottom: -12rem;
//}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2rem;
  }
}

.content-wrapper {
  padding: 1.5rem 2rem 2rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 1rem 1rem;
  }
}

#footer-bg {
  background: url('../img/flavor-shape-01.png');
  height: 100px;
  margin-top: -100px;
  margin-bottom: -1px;
  background-size: 100% 100%;
  @include media-breakpoint-down(md) {
    height: 50px;
    margin-top: -50px;
  }
}

.nav-cart .badge {
  position: absolute;
  top: -5px !important;
  left: -14px !important;
  right: unset;
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: -3px !important;
    left: -8px !important;
    right: unset;
  }
}

.invalid-feedback {
  color: #781b18;
}

.bring-to-front {
  z-index: 999;
}

.active-filters .active {
  display: inline-block !important;
}

.border-radius-top-1 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

article {
  border-radius: 15px;
  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.ctahomebanner {
  .svg-flavor-0 {
    @extend .svg-secondary-light;
  }
  .svg-flavor-1 {
    @extend .svg-quaternary-light;
  }
  .svg-flavor-2 {
    @extend .svg-tertiary-light;
  }
}

.force-tickets-style--list {
  .ticket-wrapper-class {
    @extend .list-group, .m-0;
  }
  .ticket-list-item--inner-spacing {
    @extend .mt-3;
  }
  .ticket-list-item--outer {
    @extend .list-group-item, .col-12;
    margin-bottom: -1px !important;
  }
  .ticket-list-item--inner {
    .img-wrapper {
      display: none;
    }
  }
  .ticket-wrapper-class {
    max-width: 500px;
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background: $primary !important;
}

.navbar {
  padding: 0 8px;
  li.nav-item {
    margin-bottom: 6px;
    transition: all 0.3s ease-in;
    a {
      font-weight: bold;
      padding: 16px 0px 10px 0px;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $tertiary-light;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      transition: all 0.3s ease-in;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &.active {
      background: #fff;
    }
    &.active:after {
      border-bottom-color: $primary;

    }
    &:not(.active):hover {
      background: #fff;
    }
    &:not(.active):hover:after{
      border-bottom-color: $primary;
    }
  }
}

.bg-header-bar {
  background: $tertiary-light;
  .nav-link {
    color: $primary !important;
  }
}

.share-kop-wrapper p{
  font-weight: 600;
}

figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 2px solid $primary !important;
}

.rounded-img {
  border: none;
  border-radius: 1.25rem;
  margin-bottom: .5rem;
}

.hover--box-shadow {
  text-decoration: none !important;
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

.page-heading {
  font-weight: bold;
}

.page-head-banner {
  width: 100%;
}

.dropdown-menu.sub-dropdown.show {
  @include media-breakpoint-up(md) {
    background: $tertiary-light;
  }
}

.forgot-password--label {
  color: $primary !important;
  &:hover {
    color: #193943 !important;
  }
}

.menu-block {
  border: 2px solid $white;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $white;
    a {
      color: $primary !important;
    }
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

@include media-breakpoint-up(md) {
  .position-md-static {
    position: static !important;
  }
}

.planner-table {
  td {
    border-top: none;
  }
}

.word-break {
  word-break: break-word;
}

.navbar-brand {
  display: block;
  position: absolute;
  top: -49px;
  @include media-breakpoint-down(md) {
    right: 16px;
    left: auto;
    margin: 0;
    top: -5px;
  }
  img {
    height: 10rem;
    background: white;
    border-radius: .15rem;
    padding: 12px;
    z-index: 999;
    max-width: 250px;
    max-height: 125px;
    box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.14);
    @include media-breakpoint-down(xl) {
      height: 6rem;
    }
    @include media-breakpoint-down(md) {
      z-index: 99;
      height: 62px;
      border-radius: 0;
      margin: 0;
      padding: 2px 1rem 0 1rem;
    }
  }
}


.decenter-right {
  @include media-breakpoint-up(xxxl) {
    margin-right: 8rem !important;
    width: 50%;
  }
}

.home h2#rooster-header {
  text-align: center;
}

#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.badge-wrapper {
  width: 56px;
  right: 1rem;
  span {
    font-size: .6rem;
    line-height: 10px;
  }
}

.home {
  form#rooster-form div.form-group {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h2#rooster-header {
    color: $info;
    text-align: center;
  }
}

.h-xl-100 {
  @media only screen and (min-width: 1200px) {
    height: 100% !important;
  }
}

.carousel-custom{
  overflow: hidden;
  border: solid 1px $primary;
  padding: 1rem;
}

footer {
  .social-icons {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}


#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
    background-color: $white;
  }
}

#topNav li.active a{
  color: $primary;
}

#footer-img {
  z-index: -2;
  position: relative;
}

.side-banner {
  .position-static {
    .card-body {
      display: flex;
    }
  }
}

.navbar-nav .dropdown-menu {
  background-color: $light;
  border-radius: 0px;
  top: 60px;
  padding: 0;
  border: none;
  a {
    padding: 16px 8px !important;
    color: $dark;
    &:hover {
      background-color: $primary;
      color: #fff !important;
    }
  }
}

.dropdown-menu {
  min-width: 100%;
  width: auto !important;
  left: auto;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background: #fff;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.owl-carousel {
  list-style: none;
}

.activity-tickets-widget {
  // Overwrite general widget style
  margin-bottom: 0 !important;
  h3 {
    margin-bottom: 1.5rem !important;
  }
}

.hero-cta {
  .cta-wrapper {
    bottom: 40%;
    @include media-breakpoint-down(md) {
      bottom: unset;
    }
    .cta-wrapper--inner {
      display: inline-block;
      width: 45%;
      margin-left: 130px;
      filter: drop-shadow(2px 5px 5px rgba(2, 5, 5, .6));
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
        margin-left: 0;
        margin-top: 4rem;
        h1 {
          font-size: 2rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
      @include media-breakpoint-down(sm) {
        h1 {
          display: none;
        }
        h2 {
          display: none;
        }
      }
    }
  }
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}
.space-logo {
  @include media-breakpoint-up(md){
    margin-top: 7rem;
  }
}
.sidebar-nav {
  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
  color: $white;
  .list-group-item {
    &.active {
      background: $tertiary-light;
      border-color: $tertiary-light;
      color: $primary;
    }
  }
}

.footer-cta-banners {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  .footer-cta-item:hover {
    filter: contrast(0.5);
  }
}

.quick-menu-item {
  word-break: break-all;
  &:hover {
    filter: contrast(.5);
  }
}

div#Rooster {
  padding-top: 0rem !important;
}

section#rooster, div#Rooster{
  .table-responsive-md {
    @include media-breakpoint-up(md) {
      padding: 0 2rem;
    }
    margin-bottom: 2rem;
    &:nth-of-type(1) {
      background: $quaternary-light;
    }
    &:nth-of-type(2) {
      background: $secondary-light;
    }
    &:nth-of-type(3) {
      background: $tertiary-light;
    }
  }
}

.accordion {
  .card-header {
    @extend .bg-primary;
    .accordion-banner--btn {
      @extend .text-white;
    }
  }
}

#rooster {
  h2 {
    font-size: 2.5rem !important;
  }
}

.c-fit {
  margin-left: -6%;
  width: 112%;
  margin-bottom: -2%;
}


footer a:hover {
  color: $white;
}

.page-content-wrapper {
  min-width: 100vw;
  @include media-breakpoint-up(md) {
    min-width: 0;
    width: 100%;
  }
}

// Calendar
$space: 1.5rem;

.flavor {
  bottom: 0;
}

.flavor-image {
  width: 50vw;
  left: 75vw;
  opacity: 0.3;
  max-width: unset !important;
  bottom: 2rem;
}

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }

  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0; height: $space * 10;
    border-radius: 0.15rem;
    overflow: hidden;
    background-size: cover;
    .inner {
      position: relative;
      text-align: center; text-shadow: 0 0.063em 0.063em*2 rgba(0,0,0,.5); color: $white;
      h5, .h5 { font-size: 1.5rem; color: white; }
    }
    .inner-wrapper {
      display: flex; align-items: center; justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0,0,0,.5);
      border-radius: 0.15rem;
      overflow: hidden;
    }

    .inner,
    .inner-wrapper { @include transition(); }
  }

  .extrainfo {
    padding: $space * .5;
    a { line-height: $space; }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

.login-box {
  @include media-breakpoint-down(lg) {
    border: none;
  }
}

.bottom-layer {
  object-fit: cover;
}

.footer-overlap {
  // TODO: Rename in all installations to theme-spacer, since here this name doesnt make sense.
  padding-top: 2rem;
}

.border-thick {
  border: solid 1px;
}

.search-btn {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
  }
}

.card-body {
  .media-body {
    form {
      display: inline !important;
      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
        margin-top: .5rem;
      }
    }
  }
}

.subhome-figure {
  .subhome-figure--inner {
    top: 270px;
    img {
      width: 35%;
    }
    h1 {
      top: 10px;
      left: 2rem;
    }
  }
}

.include-stretch-hover {
  transition: color 0.2s, fill 0.2s, background 0.2s;
  &:hover{
    color: $primary !important;
    fill: $primary !important;
    .btn {
      background-color: $white;
      color: $dark;
    }
    small {
      color: $primary;
    }
    .card-footer--hover {
      background: $primary !important;
    }
  }
}

.drop-shadow {
  filter: drop-shadow(2px 5px 5px rgba(2, 5, 5, 0.6));
}

.card-stretch-hover {
  small {
    transition: color 0.3s, fill 0.2s;
  }
  .hover--slide {
    max-width: 200%;
    width: 200%;
    transition: transform ease-out 0.45s;
  }
  &:hover{
    small {
      color: $tertiary !important;
    }
    //.hover--slide {
    //  transform: translateX(-10%);
    //}
  }
}

.intrest-badge {
  height: 4.2rem;
  width: 4.2rem;
  white-space: pre-wrap;
  right: 1rem;
  top: -2rem;
  p {
    vertical-align: middle;
    font-size: 0.6rem;
  }
}

.sidebar-badge-list {
  .intrest-badge {
    height: 6.2rem;
    width: 6.2rem;
  }
}

.content-sidebar {
  @include media-breakpoint-up(lg) {
    position: relative;
    right: -2rem;
    height: 100%;
  }
}

.sidebar-badge-list {
  @include media-breakpoint-up(lg) {
    margin-left: -3rem;
  }
}

.reset-position {
  top: inherit !important;
  bottom: inherit !important;
  left: inherit !important;
  right: inherit !important;
}

.fixed-width-sidebar {
  width: 400px;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 150px;
  }
}

.h-400 {
  height: 400px;
}

.full-round-banner {
  border-radius: 50rem;
  border: solid 4px;
  &::after{
    content: '';
    height: 2.3rem;
    width: 2.3rem;
    background: inherit;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50rem;
    transition: all 0.5s;
  }
  &:hover{
    &::after{
      bottom: 1rem;
      right: -0.5rem;
    }
  }
  img {
    border-radius: 50rem;
  }
}

.force-text-danger {
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $danger !important;
  }
}

.force-text-info{
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $info !important;
  }
}

.force-text-success {
  h1, h2, h3, h4, h5, h6, a:not(.btn) {
    color: $success !important;
  }
}

.border-theme_1 {
  border-color: $theme_1 !important;
}

.bg-theme_1-light {
  background-color:   $theme_1-light !important;
}

.border-theme_2 {
  border-color: $theme_2 !important;
}

.bg-theme_2-light {
  background-color:   $theme_2-light !important;
}

.quick-navigation--banner {
  @extend .p-3;
}
