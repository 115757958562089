// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

@import '../webfonts/pontiac-regular.css';
@import '../webfonts/pontiac-regular-italic.css';
@import '../webfonts/pontiac-bold.css';
@import '../webfonts/pontiac-bold-italic.css';
@import '../webfonts/pontiac-black.css';
@import '../webfonts/pontiac-black-italic.css';
@import '../webfonts/pontiac-light.css';
@import '../webfonts/pontiac-light-italic.css';

$border-width: 1px;
$border-radius: 0px;
$border-radius-lg: 0px;
$theme-colors: (
        'primary-light': $primary-light,
        'secondary-light': $secondary-light,
        'tertiary':    $tertiary,
        'tertiary-light':    $tertiary-light,
        'quaternary': $quaternary,
        'quaternary-light': $quaternary-light,
        'theme_1': #aa73ea,
        'theme_1-light': #d6c6ee,
        'theme_2': #f8de45,
        'theme_2-light': #f5eac8,
        'theme_3': #2f60ff,
        'theme_3-light': #a2c3ff
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 900;
$enable-rounded: false;
$enable-shadows: false;
$link-decoration: underline;

$font-family-base: 'Pontiac', Helvetica, Arial, Sans-Serif;
$headings-color: $primary;

@import '@/src/scss/main.scss';

form#CustomerForm-Login {
  small.text-muted a {
    color: $text-muted;
  }
}

.force-all-white {
  * {
    color: $white;
  }
  a:hover {
    color: darken($white, 10%);
  }
}

.border-info {
  border: 2px solid $info;
  border-radius: 12px;
}

footer {
  a {
    color: $white;
  }
}

div.photo-banner {
  position: relative;
  top: 0;
  left: 0;

  #bottom-layer {
    position: relative;
    top: 0;
    left: 0;
    max-height: 650px;
    min-height: 250px;
    width: 100%;
  }

  #top-layer {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-height: 150px;
    width: 100%;
  }
}

div.social-icons {
  margin-left: 10px;
  display: inline-block;

  i {
    &:hover {
      color: $primary !important;
    }
  }
}


.top-menu {
  color: $dark;
  font-weight: 400;
  font-size: 16px;

  ul {
    margin-bottom: 0;

    li {
      margin: 10px 10px 10px 10px;
      list-style: none;

      a {
        color: $dark;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }

      }

      .active {
        a {
          color: $info;
        }
      }
    }
  }
}

.social-icons {
  a {
    border: solid 1px;
    width: 24px;
    padding: 2px;
    i {
      width: 100%;
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
  @include media-breakpoint-down(lg) {
    font-size: 0.82rem;
  }
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-lg-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}


// Custom parts overview
.part--card {
  .img-wrapper {
    position: relative;
    &::after {
      content: url('../svg/golf-single.svg');
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
    }
  }
  border: none;
  .card-body {
    .card-title {
      a{
        text-decoration: none;
        color: $primary;
      }
    }
  }
}
